import React from "react";
import styled from "styled-components";
import Logo from "../assets/ayozen-logo.png";
import Home from "../assets/home.svg";
import Phone from "../assets/phone.svg";
import MenuPlus from "../assets/menubutton.svg";
import Menu from "../assets/menu.svg";
import ImageSlider from "../assets/slider-img.png";

// Import Swiper React components
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Autoplay, Pagination]);

const HeaderWrapper = styled.header`
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    height: 100%;
  }
`;
const HeaderUp = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 70px;
  }
`;
const HeaderDown = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  background: #fff;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const HeaderDownLeft = styled.div`
  width: 6%;
  height: 100%;
  background: #14477e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const HeaderDownCenter = styled.div`
  width: 89.3%;
  height: 100%;
  position: relative;
  @media (min-width: 1541px) and (max-width: 1640px) {
    width: 88.7%;
  }
  @media (min-width: 1441px) and (max-width: 1540px) {
    width: 88.2%;
  }
  @media (min-width: 1341px) and (max-width: 1440px) {
    width: 87.8%;
  }
  @media (min-width: 1241px) and (max-width: 1340px) {
    width: 87.3%;
  }
  @media (min-width: 1141px) and (max-width: 1240px) {
    width: 86.8%;
  }
  @media (min-width: 1025px) and (max-width: 1140px) {
    width: 86.1%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  & > #overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  & > .absolute {
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 20;
    @media (min-width: 1221px) and (max-width: 1440px) {
      left: 24%;
    }
    @media (min-width: 1025px) and (max-width: 1220px) {
      left: 12%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      left: 18%;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      left: 7%;
      top: 20%;
    }
    @media (min-width: 426px) and (max-width: 599px) {
      left: 7%;
      top: 10%;
    }
    @media (min-width: 376px) and (max-width: 425px) {
      left: 16%;
      top: 15px;
    }
    @media (min-width: 321px) and (max-width: 375px) {
      left: 12%;
      top: 15px;
    }
    @media (max-width: 320px) {
      left: 7%;
      top: 15px;
    }

    & > .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 9999999;
      & > div {
        color: #fff;
      }
      & > .sub-text {
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        margin-top: 10px;
        @media (max-width: 425px) {
          font-size: 15px;
          line-height: 20px;
        }
      }
      & > .title {
        font-size: 26px;
        font-weight: 700;
        line-height: 56px;
        @media (max-width: 425px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      & > .sub-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        margin-bottom: 25px;
        @media (min-width: 426px) and (max-width: 599px) {
          font-size: 38px;
          line-height: 36px;
        }
        @media (max-width: 425px) {
          font-size: 20px;
          line-height: 36px;
        }
      }
      & > .v-line {
        width: 2px;
        height: 30px;
        background: #fff;
        @media (max-width: 425px) {
          height: 15px;
        }
      }
    }
  }

  & > .swiper-container {
    width: 100%;
    height: 100%;
    & > .swiper-pagination-bullets {
      background: transparent;
      width: min-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 240px;
      top: calc(50% + 120px);
      left: calc(100% - 16px);

      margin-top: -240px;
      & > .swiper-pagination-bullet {
        border-radius: 0;
        background: #bcbcbc;
      }
      & > .swiper-pagination-bullet-active {
        background: #eec218;
      }
    }
  }
`;
const MenuText = styled.span`
  transform: rotate(-90deg);
  color: #fff;
  margin-top: 20px;
`;
const HeaderDownRight = styled.div`
  width: 90px;
  height: 100%;
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const HeaderUpWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const HeaderUp1 = styled.div`
  height: 100%;
  width: 15%;
  background: #14477e;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const HeaderUp2 = styled.div`
  height: 100%;
  width: 30.5%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: calc(100% - 70px);
    justify-content: flex-start;
  }

  & img {
    width: 180px;
    @media (max-width: 768px) {
      margin-left: 10px;
      width: 140px;
    }
  }
`;
const HeaderUp3 = styled.div`
  height: 100%;
  width: 30%;
  background: #f8f8f8;
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 45%;
  }
  & > div {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    & span {
      font-size: 16px;
      color: #555555;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
      & img {
        margin-right: 10px;
      }
    }
  }
`;
const HeaderUp4 = styled.div`
  height: 100%;
  width: 19.8%;
  background: #fff;
  @media (max-width: 768px) {
    display: none;
  }
  & > div {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & span {
      font-size: 16px;
      color: #555555;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
      & img {
        margin-right: 5px;
      }
    }
  }
`;
const HeaderUp5 = styled.div`
  height: 100%;
  width: 90px;
  background: #14477e;
  @media (max-width: 768px) {
    height: 70px;
    width: 70px;
  }
  & > img {
    @media (max-width: 768px) {
      height: 70px;
      width: 70px;
    }
  }
`;
function Header() {
  return (
    <HeaderWrapper>
      <HeaderUp>
        <HeaderUpWrapper>
          <HeaderUp1></HeaderUp1>
          <HeaderUp2>
              <img src="https://ulastirmasurasi.gov.tr/assets/images/indir.png" alt="" />
          </HeaderUp2>
          <HeaderUp3>
            <div>
            
              <span>
                {" "}
                Büyükşehir mahallesi Beylikdüzü caddesi No5/1 D:57 Beylikdüzü / İSTANBUL{" "}
              </span>
            </div>
          </HeaderUp3>
          <HeaderUp4>
            <div>
              <span>
                <img src={Phone} alt="" />
              </span>
              <span>(0212) 830 49 59</span>
            </div>
          </HeaderUp4>
          <HeaderUp5>{/*   <img src={MenuPlus} alt="" /> */}</HeaderUp5>
        </HeaderUpWrapper>
      </HeaderUp>
      <HeaderDown>
        <HeaderDownLeft>
          {/*           <span>
            {" "}
            <img src={Menu} alt="" />
          </span>
          <MenuText>MENÜ</MenuText> */}
        </HeaderDownLeft>
        {/*         <HeaderDownCenter src={ImageSlider} alt=""></HeaderDownCenter> */}
        <HeaderDownCenter>
          <div id="overlay"></div>

          <div className="absolute">
            <div className="flex">
              <div className="title">AYÖZEN</div>
              <div className="sub-title">İnşaat • Mühendislik • Müşavirlik</div>
{/*    <div className="v-line"></div>
              <div className="sub-text">YAPI MALZEMELERİ</div>
              <div className="v-line"></div> */}
            </div>{" "}
          </div>
          <Swiper
            spaceBetween={250}
            slidesPerView={1}
            pagination
            autoplay
            /*   onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)} */
          >
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={ImageSlider}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SwiperSlide>
          </Swiper>
        </HeaderDownCenter>
        <HeaderDownRight> </HeaderDownRight>
      </HeaderDown>
    </HeaderWrapper>
  );
}

export default Header;
