import React, { useState } from "react";
import styled from "styled-components";
import Logo_W from "../assets/logo-white.png";
import Home from "../assets/home_gray.svg";
import Email from "../assets/email_gray.svg";
import Phone from "../assets/phone_gray.svg";
const Wrapper = styled.div`
  width: 100%;
  height: 800px;
  background: #faf9f9;
  position: relative;
  @media (min-width: 1024px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 100%;
    margin-top: 80px;
  }
`;
const Bottom = styled.div`
  width: 100%;
  height: 200px;
  background: #1a1a1a;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
const Container = styled.div`
  padding: 0 90px;
  height: calc(100% - 200px);
  position: absolute;
  z-index: 30;
  bottom: 90px;
  left: 0;
  width: calc(100% - 180px);
  @media (max-width: 768px) {
    position: relative;
    height: 100%;
    bottom: unset;
    width: 100%;
    padding: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftBox = styled.div`
  width: calc(100% - 20%);
  background: #0d0d0d;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  color: #fff;
  @media (min-width: 1024px) {
    width: calc(100% - 10%);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: 768px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
  & > img {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 200px;

    @media (max-width: 1200px) and (min-width: 1025px) {
      margin-right: 20px;
    }
    @media (max-width: 768px) {
      margin: 40px 50px;
    }
  }
  & > div {
    align-items: flex-start;
    margin: 35px 0;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      margin: 15px 50px;
    }
    &.last {
      @media (max-width: 768px) {
        margin: 15px 50px 30px 50px;
      }
    }
    & > img {
      margin-right: 20px;
    }
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Subtitle = styled.span`
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 16px;
  font-weight: 700;
`;
const Text = styled.span`
  font-size: 12px;
`;
const RightBox = styled.div`
  width: 50%;
  background: #333439;
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
  & > .selected {
    width: 100%;
  }
`;
const Bar = styled.div`
  position: absolute;
  right: 10%;
  top: 40%;
  display: flex;
  flex-direction: column;
  @media (max-width: 425px) {
    top: 20%;
  }
  & > button {
    border: none;
    background: transparent;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    margin: 10px 0;

    &.active {
      transform: scale(1.2);
      font-size: 25px;
    }
    &.btn {
      outline: none;
    }
  }
`;
function Footer() {
  const [selectedTab, setSelectedTab] = useState("01");
  const openTab = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <Wrapper>
      <Container>
        <Column>
          <LeftBox>
            <img src="https://ulastirmasurasi.gov.tr/assets/images/download.png" alt="" />
            <div>
              <img src={Home} alt="" />
              <div>
                <Subtitle>ADRES:</Subtitle>
                <Text>
                  Büyükşehir mahallesi Beylikdüzü caddesi No5/1 D:57 <br /> Beylikdüzü İstanbul                
                </Text>
              </div>
            </div>
            <div>
              {" "}
              <img src={Email} alt="" />
              <div>
                <Subtitle>EMAİL:</Subtitle>
                <Text>betul@ayozenyapi.com</Text>
              </div>
            </div>
            <div className="last">
              {" "}
              <img src={Phone} alt="" />
              <div>
                <Subtitle>TELEFON:</Subtitle>
                <Text>(0212) 830 49 59 </Text>
              </div>
            </div>
          </LeftBox>
        </Column>
      </Container>
      <Bottom />
    </Wrapper>
  );
}

export default Footer;
