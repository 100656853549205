import React from "react";
import styled from "styled-components";
import Logo from "../assets/ayozen-logo.png";
import Home from "../assets/home.svg";
import Phone from "../assets/phone.svg";
import MenuPlus from "../assets/menubutton.svg";
import Menu from "../assets/menu.svg";
import ImageSlider from "../assets/slider-img.png";
import Image1 from "../assets/img1.png";
import Image2 from "../assets/img2.png";
import Edit from "../assets/edit.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  padding: 50px 260px 0 260px;
  @media (max-width: 1540px) and (min-width: 1441px) {
    padding: 40px 200px 0 200px;
  }
  @media (max-width: 1440px) and (min-width: 1280px) {
    padding: 40px 230px 0 230px;
  }
  @media (max-width: 1279px) and (min-width: 1201px) {
    padding: 40px 130px 0 130px;
  }
  @media (max-width: 1200px) and (min-width: 1025px) {
    padding: 40px 130px 0 130px;
  }
  @media (max-width: 1024px) and (min-width: 870px) {
    padding: 40px 180px 0 180px;
  }
  @media (max-width: 869px) and (min-width: 769px) {
    padding: 40px 100px 0 100px;
  }
  @media (max-width: 768px) and (min-width: 426px) {
    padding: 20px 80px 0 80px;
  }
  @media (max-width: 425px) {
    padding: 20px 40px 0 40px;
  }
  & > .products-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > span {
      margin-left: 22px;
      font-size: 26px;
      line-height: 100px;
      font-weight: 700;
      color: #333333;
      letter-spacing: 1px;
    }
  }
  & > .products {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & > div {
      font-size: 20px;
      line-height: 100px;
      font-weight: 700;
      color: #333333;
      letter-spacing: 1px;
      @media (max-width: 768px) {
        line-height: 50px;
      }
    }
  }
`;

const Title = styled.div`
  position: relative;
  background: #fff7b5;
  width: 500px;
  height: 10px;
  @media (max-width: 768px) and (min-width: 426px) {
    width: 500px;
  }
  @media (max-width: 425px) and (min-width: 376px) {
    width: 400px;
  }
  @media (max-width: 375px) and (min-width: 321px) {
    width: 320px;
  }
  @media (max-width: 320px) {
    width: 275px;
  }

  .text {
    position: absolute;
    top: -36px;
    left: 4%;
    color: #345989;
    @media (max-width: 768px) and (min-width: 669px) {
      top: -22px;
      font-size: 25px;
      top: -27px;
      left: 16%;
    }
    @media (max-width: 668px) and (min-width: 526px) {
      top: -22px;
      font-size: 20px;
      top: -18px;
      left: 13%;
    }
    @media (max-width: 525px) and (min-width: 426px) {
      top: -22px;
      font-size: 16px;
      top: -14px;
      left: 10%;
    }
    @media (max-width: 425px) and (min-width: 376px) {
      top: -22px;
      font-size: 22px;
    }
    @media (max-width: 375px) and (min-width: 321px) {
      top: -18px;
      font-size: 19px;
    }
    @media (max-width: 320px) {
      top: -15px;
      font-size: 16px;
    }
  }
`;
const Column = styled.div`
  margin-top: 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 20px;
  }
`;
const About = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  & > div {
    color: #555555;
    font-size: 15px;
    margin-bottom: 60px;
    & > p {
      line-height: 28px;
      @media (max-width: 425px) {
        font-size: 14px;
      }
    }
  }
`;
const Section = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  & > div {
    color: #555555;
    font-size: 15px;
    margin-bottom: 60px;
    text-align: center;
    & > p {
      line-height: 28px;
      @media (max-width: 425px) {
        font-size: 14px;
      }
      &.bold {
        font-weight: 700;
      }
    }
  }
`;
const Subtitle = styled.h6`
  color: #444444;
  font-size: 24px;
  @media (max-width: 1024px) {
    margin-block-start: 1em;
    margin-block-end: 1em;
    font-size: 18px;
  }
`;
const Subtitle2 = styled(Subtitle)`
  text-align: center;
`;
const Column2 = styled(Column)`
  margin-top: 0px;
`;
const ButtonGrup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ButtonLeft = styled.span`
  background: red;
  color: #fff;
  padding: 20px 40px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background: #14477e;
  @media (max-width: 1440px) and (min-width: 1201px) {
    padding: 20px 20px;
  }
  @media (max-width: 1200px) and (min-width: 1025px) {
    padding: 20px 20px;
  }
  @media (max-width: 425px) {
    padding: 20px 20px;
    font-size: 12px;
  }
`;
const ButtonRight = styled.span`
  background: green;
  color: #555555;
  padding: 20px 40px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: #f8f8f8;
  @media (max-width: 1440px) and (min-width: 1201px) {
    padding: 20px 20px;
  }
  @media (max-width: 1200px) and (min-width: 1025px) {
    padding: 20px 20px;
  }
  @media (max-width: 425px) {
    padding: 20px 12px;
    font-size: 12px;
  }
`;
const Images = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 425px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: center;
  }
  & > img {
    margin-bottom: 20px;
    &.img1 {
      @media (max-width: 1440px) and (min-width: 1201px) {
        width: 35%;
      }
      @media (max-width: 1200px) and (min-width: 1025px) {
        width: 30%;
      }
      @media (max-width: 1024px) and (min-width: 769px) {
        width: 40%;
      }
      @media (max-width: 768px) and (min-width: 426px) {
        width: 40%;
      }
      @media (max-width: 425px) {
        width: 300px;
      }
    }
    &.img2 {
      margin-left: -24px;
      @media (max-width: 1440px) and (min-width: 1201px) {
        width: 60%;
      }
      @media (max-width: 1200px) and (min-width: 1025px) {
        width: 50%;
      }
      @media (max-width: 1024px) and (min-width: 769px) {
        width: 60%;
      }
      @media (max-width: 768px) and (min-width: 426px) {
        width: 60%;
      }
      @media (max-width: 425px) {
        width: 300px;
        margin-left: 0 !important;
      }
    }
  }
`;

function Product() {
  return (
    <Wrapper>
      <Container>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
        
        </div>

        <Column>
          <About>
            <Subtitle>HAKKIMIZDA</Subtitle>
            <div>
              <p>
                Hedefimiz; ürün kalitemizden ödün vermeden, müşteri
                memnuniyetini öncelik belirleyerek, ürünlerimizi müşterilerimize
                en uygun fiyata, zamanında ve eksiksiz olarak sunarak kusursuz
                hizmet sağlamak.
              </p>
            </div>

            <ButtonGrup>
              <ButtonLeft>İLETİŞİM</ButtonLeft>
              <ButtonRight>(0212) 830 49 59</ButtonRight>
            </ButtonGrup>
          </About>
          <Images>
            <img src={Image1} alt="" className="img1" />
            <img src={Image2} alt="" className="img2" />
          </Images>
        </Column>
        <Column2>
          <Section>
            <Subtitle2>VİZYONUMUZ & MİSYONUMUZ</Subtitle2>
            <div>
              <p>
                Müşterilerimizin beklenti ve ihtiyaçlarına yönelik hareket edip,
                en uygun maliyet ve en süratli servis sağlayarak, yüksek
                nitelikli hizmetler sunmak.
              </p>
              <p className="bold">
                Tüm projelerinizde, ihtiyaçlarınızda güçlü ve güvenilir
                ortağınız olarak yanınızdayız...{" "}
              </p>
              <p className="bold">
                Siz hayal edin, biz en süratli şekilde en kalitelisini temin
                edelim.
              </p>
            </div>
          </Section>
        </Column2>

{/* <div className="products-title">
          {" "}
          <img src={Edit} alt="" />
          <span>ÜRÜNLER</span>
        </div>
        <div className="products">
          <div>DEMİR</div>
          <div>ÇİMENTO</div>
          <div>ALÇI</div>
          <div>KİREÇ</div>
        </div> */}
      </Container>
    </Wrapper>
  );
}

export default Product;
