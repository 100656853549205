import React, { useState } from "react";
import styled from "styled-components";
import Q_Left from "../assets/quote_left.svg";
import Q_Right from "../assets/quote_right.svg";
import A_Up from "../assets/arrow_up.svg";
import A_Down from "../assets/arrow_down.svg";
import Line from "../assets/line.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 480px;
  position: relative;
  @media (max-width: 768px) {
    height: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  & > .wrapper {
    display: flex;
    flex-direction: column;
    & > .gray {
      background: #faf9f9;
      height: 50px;
      width: 100%;
    }
  }
`;
const ReferencesBox = styled.div`
  width: 70%;
  height: 360px;
  border: 60px solid #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  margin-bottom: -45px;
  @media (max-width: 768px) {
    border: 30px solid #fbfbfb;
    width: 100%;
  }
  & > .title {
    font-size: 20px;
    line-height: 26px;
    color: #444444;
  }
  & > .text {
    margin-top: 27px;
    font-size: 14px;
    line-height: 24px;
    color: #555555;
    text-align: center;
    margin-bottom: 27px;
  }
  & > .btn {
    width: 195px;
    height: 54px;
    border: none;
    padding: 10px 30px;
    margin-bottom: 20px;
    border-radius: 50px;
    font-size: 12px;
    line-height: 26px;
  }
  & > .share {
    background: #14477e;
    color: #eec218;
  }
  & > .contact {
    background: #eec218;
    color: #14477e;
  }
`;
const ReferencesContainer = styled.div`
  background: #fff;
  width: 60%;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
  }

  & > .empty {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 768px) {
      display: none;
    }
    & > .gray {
      background: #faf9f9;
      height: 50px;
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #13151e;
  padding: 0 100px;
  color: #fff;
  height: 336px;
  @media (max-width: 768px) {
    height: 100%;
    padding: 50px 50px;
    flex-direction: column;
  }

  & > .author-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
      margin-right: 0px;
      text-align: center;
    }
    & > .author {
      font-weight: 700;
      font-size: 22px;
      line-height: 25px;
    }
    & > .via {
      font-weight: 700;
      color: #ff7300;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 3px;
    }
    & > .quotes {
      margin-top: 10px;
      & > img {
        width: 15px;
        margin-right: 5px;
      }
    }
  }
  & > .desc-wrapper {
    margin-right: 20px;
    font-size: 16px;
    line-height: 28px;
    font-style: italic;
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
      text-align: center;
    }
  }
  & > .arrow-wrapper {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    & > img {
      width: 20px;
      margin-bottom: 10px;
    }
  }
`;
function Testimonial() {
  const [selectedTab, setSelectedTab] = useState("01");
  const openTab = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <Wrapper>
      <Container>
        <ReferencesContainer>
          <div className="empty">
            <span></span>
            <span className="gray"></span>
          </div>
          <ReferencesBox>
            <div className="title">REFERANSLARIMIZ</div>
            <div className="text">
              Rem iplsum dolor sit amet, consectetuer adipiscingelit, sed diam
              nonummy.
            </div>
            <button className="btn contact">İLETİŞİME GEÇ</button>
            <button className="btn share">PAYLAŞ</button>
          </ReferencesBox>
        </ReferencesContainer>
        <div className="wrapper">
          <TestimonialContainer>
            <div className="author-wrapper">
              <div className="author">Ivan Shubian</div>
              <div className="via">VIA TWITTER</div>
              <div className="quotes">
                <img src={Q_Left} alt="" />
                <img src={Q_Right} alt="" />
              </div>
            </div>
            <div className="desc-wrapper">
              "“Buis sed odio sit amet nibh vulputate cursus am mauris Morbi
              accumsan ipsuy veli Nam nec tellus il tincidu ilm auctor Class
              aptent taciti sociosqu libero am lem thats odio Sed non mauris
              vitae erat.”"
            </div>
            <div className="arrow-wrapper">
              <img src={A_Up} alt="" />
              <img src={Line} alt="" />
              <img src={A_Down} alt="" />
            </div>
          </TestimonialContainer>
          <div className="gray"></div>
        </div>
      </Container>
    </Wrapper>
  );
}

export default Testimonial;
