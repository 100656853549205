import logo from "./logo.svg";
import "./App.css";
import Header from "./sections/Header";
import Product from "./sections/Product";
import Testimonial from "./sections/Testimonial";
import Footer from "./sections/Footer";

function App() {
  return (
    <div>
      <Header />
      <Product />
      {/*  <Testimonial /> */}
      <Footer />
    </div>
  );
}

export default App;
